export const msalConfig = {
    auth: {
      clientId: "8dd9ba49-c424-4f95-a8e3-41b28d699623",
      authority: "https://login.microsoftonline.com/fad42abb-dfda-43f0-9120-b18e6e86169d", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://ledger.dev.hosting.gss.gov.uk"
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["api://fe05e770-72e9-4753-ae20-1873b0ed7770/Files.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.

