import React from 'react';
import {Notes}  from './features/notes/Notes';
import { PageLayout } from "./components/PageLayout";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal  } from "@azure/msal-react";
//import { loginRequest } from "./authConfig";

import  { useState } from "react";
import { loginRequest } from "./authConfig-dev";
import Button from "react-bootstrap/Button";
import { string } from 'yargs';

//import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';




//function ProfileContent() {
//  const { instance, accounts, inProgress } = useMsal();
//  const [accessToken, setAccessToken] = useState("");

//  const name = accounts[0] && accounts[0].name;
  //const { accounts } = useMsal();

  //const name = accounts[0].name;

 
//  return (
 //   <>
 //     <h5 className="wrapper3"> Welcome {name} </h5>
        
//    </>
//);
//};  <ProfileContent />






function App() {
  return (
    <PageLayout>
      <AuthenticatedTemplate>
        <Notes />
      </AuthenticatedTemplate>
       <UnauthenticatedTemplate>
          <h5 className="wrapper3"> You are not signed in! Please sign in</h5>
      </UnauthenticatedTemplate>
    </PageLayout>
   
  );
}
export default App;



