import { configureStore } from '@reduxjs/toolkit';
import notesReducer from '../features/notes/notesSlice';


// download Redux DevTools for debugging in Chrome.  extemely useful.
// ref this doc for background for redux.  https://www.freecodecamp.org/news/redux-for-beginners-the-brain-friendly-guide-to-redux/
// ref https://www.taniarascia.com/redux-react-guide/
// addthe notesRueducer "reducer" exported from the slice..  Notvce it's not the completely the same name, but REDUX chnages to common format.
// the store holds all our reducers and manages them for us.   This just makes our code more manageable to maintain.
export const store = configureStore({
  reducer: {
    notes: notesReducer,
  },

});



// Whenever we use dispatch, it’s type will be AppDispatch
export type AppDispatch = typeof store.dispatch;

// We also declared RootState, for strongly-typed access to our Redux state
export type RootState = ReturnType<typeof store.getState>;





