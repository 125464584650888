import React, { useState, useEffect, useRef, useReducer   } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { fetchNotes , fetchNotes2, fetchNotes3, fetchNotes4, fetchNotes5 } from './notesSlice';
import { loginRequest } from "../../authConfig-dev";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import Radio from '@mui/material/Radio';
import {reset} from './notesSlice';
import {createTheme} from "@material-ui/core/styles";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import 'reactjs-popup/dist/index.css';
import Accordion from '@mui/material/Accordion';
import { blue } from '@mui/material/colors';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeProvider } from '@mui/material/styles';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import Modal from '@mui/material/Modal';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CSVLink } from "react-csv";
import Box from '@mui/material/Box';




const onFocusOut = (name: any, value: any, dispatch: any, formState: any) => {
    const { hasError, error } = validateInput(name, value)
    console.log("asdadda",name)
    let isFormValid = true
    for (const key in formState) {
      const item = formState[key]
      if (key === name && hasError) {
        isFormValid = false
        break
      } else if (key !== name && item.hasError) {
      isFormValid = false
    } else {
      break
    }
    
 
  }
  dispatch({
    type: UPDATE_FORM,
    data: { name, value, hasError, error, touched: true, isFormValid },
    })
    }

    
  export const Spreadsheet =(name:any, proc:any) => {

    console.log("proc is",proc)
 
    const reduxState = useAppSelector(state => state);
  
    const doubled3 = reduxState.notes.notes2.map(number => ({
          Account: number.Account,
          Date: number.Date,
          Hostedzone: number.Hostedzone,
          Username: number.Username,
          Name: number.Name,
          Delegated: number.Delegation,
         
        }))

    const doubled1 = reduxState.notes.notes2.map(number => ({
          Account: number.Account,
          Date: number.Date,
          ResourceRecord: number.ResourceRecord,
          Hostedzone: number.Hostedzone,
          Username: number.Username,
          HZ: number.HZ,
          Alias: number.Alias,
          Type: number.Type,
          AWSType: number.AWSType,
         
        }))

    console.log("just checking it has right value--->",name)

    switch (name) {
      case "HOSTEDZONE":
       
        console.log("hit hos")
        if (proc === true) {
   
        return(
        <CSVLink className="wrapper3"
        
       
        data={doubled3}
        headers={headers3}
        filename="results.csv"
        target="_blank"
      >
        Export to CSV
        
      </CSVLink>
      )
        } else {
          return
        }

      case "RESOURCE":
        
        console.log("hit res")
        if (proc === true) {
       
        return(
          
          <CSVLink className="wrapper3"
          
         
          data={doubled1}
          headers={headers1}
          filename="results.csv"
          target="_blank"
        >
          Export to CSV
          
        </CSVLink>
        )
      } else {
        return
      }

      case "DELEGATED":
       
        console.log("hit del")
         if (proc === true) {
       
         return(
        <CSVLink className="wrapper3"
        
       
        data={doubled3}
        headers={headers3}
        filename="results.csv"
        target="_blank"
      >
        Export to CSV
        
      </CSVLink>
      )
    } else {
      return
    }
    default:
      console.log("hit the break")
      return ;
         }
  }

  function UpDate(name: any, value: any, dispatch: any, formState: any) {
      console.log("dddddddd",name)
      console.log("ee",value)
      
      dispatch({
        type: UPDATE_FORM,
        data: { name, value},
        })
  }
  


const is = {
  name: { value: "", touched: false, hasError: true, error: "" },
  isFormValid: false,
  account: { value: "", touched: false, hasError: true, error: "" },
  account2: { value: "", touched: false, hasError: true, error: "" },
}

const formsReducer = (state: any, action: any) => {
  switch (action.type) {
  case UPDATE_FORM:
  const { name, value, hasError, error, touched, isFormValid } = action.data
  console.log("name--->",name)
  console.log("value--->",value)
  return {
   ...state,
  // update the state of the particular field,
  // by retaining the state of other fields
  [name]: { ...state[name], value, hasError, error, touched },
  isFormValid,
  }
  default:
  return state
  }
}



const UPDATE_FORM = "UPDATE_FORM"



  export const onInputChange = (name: any, value: any, dispatch: any, formState: any) => {
    
    const { hasError, error } = validateInput(name, value)
    let isFormValid = true
    
    for (const key in formState) {
      const item = formState[key]
      console.log("item-->",item)
      // Check if the current field has error
      if (key === name && hasError) {
          isFormValid = false
          console.log("isform-->",isFormValid)
          break
      } else if (key !== name && item.hasError) {

      // Check if any other field has error
         isFormValid = false
         console.log("isform-->",isFormValid)
         break
      } else {
        break
      }
    }
  

  dispatch({
    type: UPDATE_FORM,
    data: { name, value, hasError, error, touched: false, isFormValid },
    })
  }

  export const validateInput = (name: any, value: any) => {
    
     let hasError = false,
     error = ""
     switch (name) {
       case "name":
         if (value.trim() === "") {
        hasError = true
          error = "HostName cannot be empty"
        
          } else if (!/^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/.test(value)) {
          hasError = true
          error = "Invalid Name. Do not use @ or Spaces"
          } else {
          hasError = false
          error = ""
          }
          break
        case "account":
        if (value.trim() === "") {
        hasError = true
         error = "Hostname cannot be empty"
        } else if (!/^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/.test(value)
        ) {
         hasError = true
          error = "Invalid Name. Do not use @ or Spaces"
        } else {
         hasError = false
          error = ""
       }
        break
        case "account2":
          if (value.trim() === "") {
          hasError = true
           error = "Account name cannot be empty"
          } else if (!/^\d{12}$/.test(value)
          ) {
           hasError = true
            error = "Invalid AWS Account Number"
          } else {
           hasError = false
            error = ""
         }
          break
        default:
        break
    }
    return { hasError, error }
  }


const style = {
 
  h1: { color: 'black' },
  '*::-webkit-scrollbar': {
    width: '0.3em',
  },
  '*::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'inset 0 0 9px rgba(0,0,0,0.00)',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    outline: '2px solid slategrey',
  },
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 14,
  p: 2,


};





export const Testing = () => {
    //We import the useAppSelector hook to get the state from the store.  this pulls in all the info from the state.  ref  state => state
    // state passed into useAppSelector by redux.
  const reduxState = useAppSelector(state => state);
  console.log("hey2")
return (
 
  <div >

{reduxState.notes.notes2.length ? reduxState.notes.notes2.map(notes1 => ( // reduxState references the above store.  the name 'notes' is the name used in the store.ts when adding the reducer to store; sop references the reducer from the slice crerates in notesslice.ts
  // notes2 refeerences  NotesState2.notes2: Notejc[].   mal then used to iterate over this state.; whkch are the objects in the array/list.

    <div className="centeralign" >
    <>
          </>  
      
          <p> {notes1.SK} </p>
         
         
       ----------------------------------------------------------------------------------------------------------
      
           </div>   
 ))
 : <div >

<p> The list is empty  </p>
</div>
 
 
 }
 </div> 
 
 );
 }

export const Testing2 = () => {
  const reduxState = useAppSelector(state => state);
  console.log("hey2")

return (
  

  <div >
    
    {reduxState.notes.notes2.length ? reduxState.notes.notes2.map(notes1 => (
 
  
    <div className="centeralign" >
    <>
          </>  
      
        
          <p> Account:  {notes1.Account} </p>
         
       ----------------------------------------------------------------------------------------------------------
      
           </div>   
  ))
  : <div >

<p> The list is empty  </p>
 </div>
  
  
  }
  </div> 
  
  );
  }



export const Testing3 = () => {
  const reduxState = useAppSelector(state => state);
  console.log("hey2")
return (
 
 
  <div >
    
  {reduxState.notes.notes2.length ? reduxState.notes.notes2.map(notes1 => (
          
      <div className="centeralign" >
          
       <>
            </>  
      
          <p> Account:  {notes1.Account} </p>
          <p> Date:  {notes1.Date} </p>
          <p> Hostedzone ID:  {notes1.Hostedzone}</p>
          <p> Hostedzone Name:  {notes1.Name}</p>
          <p> Username:  {notes1.Username}</p>
          <p> Delegated:  {notes1.Delegation}</p>


       ----------------------------------------------------------------------------------------------------------
      
           </div>   
 ))
 : <div >

<p> The list is empty  </p>
</div>
 
 
 }
 </div> 
 
 );
 }


export const Testing4 = () => {
  const reduxState = useAppSelector(state => state);
  console.log("hey2", reduxState.notes.notes2)
return (
 
  
  <div >
    
  {reduxState.notes.notes2.length ? reduxState.notes.notes2.map(notes1 => (
          
      <div className="centeralign" >
        
     <>
          </>  
      
          <p> Account:  {notes1.Account} </p>
          <p> Date:  {notes1.Date} </p>
          <p> Resource Record:  {notes1.ResourceRecord}</p>
          <p> Username:  {notes1.Username}</p>
          <p> Alias:  {notes1.Alias}</p>
          <p> AWS-Boundary-Associated:  {notes1.AWSType}</p>
          <p> Record Type:  {notes1.Type}</p>
          <p> Hosted Zone:  {notes1.HZ}</p>

       
       ----------------------------------------------------------------------------------------------------------
      
        </div>   
  ))
  : <div >

<p> The list is empty  </p>
 </div>
  
  
  }
  </div> 
  
  );
  }
 
      

function ScrollDialog(props: any, scrub: any) {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
  const j = props.name
  console.log("ive been called")

  if (j === "HOSTEDZONE") {
    const j = "HOSTEDZONE"
  }
 
  

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  
  useEffect(() => {
    if (open) {
      
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  return (
    <div>
     
    
       <Box sx={{ '& button': { m: 1 } }}>
       <div className="vertical-center">
         <Button  onClick={handleClickOpen('body')} type="submit" variant="contained" size="small"  >
           Submit
         </Button>
       </div>
     </Box>
     <Dialog
      
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >

        <DialogTitle id="scroll-dialog-title">Results</DialogTitle>     

        <DialogContent dividers={scroll === 'paper'}>

          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
           
           <p>
         
      {(() => {
        console.log("value --->",j)
        if (j === "jon" ) {
          console.log("AAAAAAA")
          return  <Testing2/>; 

        } else if (j === "jon2" ) {
          console.log("BBBBBBBB")
          return  <Testing/>; 
        
        } else if (j  === "JON3" ) {
          console.log("BBBBBBBB")
          return  <Testing4/>; 

        } else if (j === "HOSTEDZONE") {
          console.log("CCCCCCCCC")
          return <Testing3/>; 

        } else if (j === "RESOURCE") {
          console.log("CCCCCCCCC")
          return <Testing4/>; 

        } 
        else if (j === "DELEGATED") {
          console.log("CCCCCCCCC")
          return <Testing3/>; 

        } 
        else if (j === "RES") {
          console.log("DDDDDDDD")
          return <Testing3/>; 

        } 
      
          
      })()}
   
      </p>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        
        </DialogActions>
      </Dialog>
    </div>
  );
}


  const headers1 = [
    { label: "Account Number", key: "Account" },
    { label: "Date", key: "Date" },
    { label: "ResourceRecord", key: "ResourceRecord" },
    { label: "Hostedzone", key: "Hostedzone" },
    { label: "HZ", key: "HZ" },
    { label: "Username", key: "Username"},
    { label: "Alias", key: "Alias" },
    { label: "Type", key: "Type" },
    { label: "AWSType", key: "AWSType"},
  

  ];


  const headers3 = [
    { label: "Account Number", key: "Account" },
    { label: "Date", key: "Date"},
    { label: "Hosted zone", key: "Hostedzone"},
    { label: "Username", key: "Username"},
    { label: "Delegated", key: "Delegated"},
    { label: "Name", key: "Name"},

  ];

  const headers2 = [
    { label: "Hostedzone", key: "Hostedzone" },
  

  ];

  


  declare module '@mui/material/styles' {
    interface Theme {
      status: {
        danger: string;
      };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
      status?: {
        danger?: string;
      };
    }
  }

export const Notes = () => {

    const [formState, dispatch] = useReducer(formsReducer, is)

    //We import the useAppSelector hook to get the state from the store.  this pulls in all the info from the state.  ref  state => state
    const reduxState = useAppSelector(state => state);

    // We import the useAppDispatch hook  -- used to dspatch the action to the store/reducer
    const reduxDispatch = useAppDispatch(); 

    const [newNoteContent, setNewNoteContent] = useState('');
    const [newNoteContent2, setNewNoteContent2] = useState('');
    const [newNoteContent3, setNewNoteContent3] = useState('');
    const [newNoteContent4, setNewNoteContent4] = useState('');
    const [newNoteContent7, setNewNoteContent7] = useState('');

    const [proc, setproc] = useState(false);


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const [open3, setOpen3] = React.useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);

    const [open4, setOpen4] = React.useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);

    const [open5, setOpen5] = React.useState(false);
    const handleOpen5 = () => setOpen5(true);
    const handleClose5 = () => setOpen5(false);

   

  
    
    const doubled3 = reduxState.notes.notes2.map(number => ({
      Account: number.Account,
      Date: number.Date,
      Hostedzone: number.Hostedzone,
      Username: number.Username,
      Name: number.Name,
      Delegated: number.Delegation,
     
    }))

    const doubled2 = reduxState.notes.notes2.map(number => ({
      Hostedzone: number.SK,
   
    }))

    const doubled1 = reduxState.notes.notes2.map(number => ({
      Account: number.Account,
      Date: number.Date,
      ResourceRecord: number.ResourceRecord,
      Hostedzone: number.Hostedzone,
      Username: number.Username,
      HZ: number.HZ,
      Alias: number.Alias,
      Type: number.Type,
      AWSType: number.AWSType,
     
    }))


    const { instance, inProgress, accounts } = useMsal();
  
    const [newNoteContent6, setNewNoteContent6] = React.useState('a');

    const handleChnage = (event: React.ChangeEvent<HTMLInputElement>) => {setNewNoteContent6(event.target.value);};

    const handleChnage2 = (event: React.ChangeEvent<HTMLInputElement>) => {setNewNoteContent2(event.target.value);};

   
    const controlProps = (item: string) => ({
      checked: newNoteContent6 === item,
      onChange: handleChnage,
      value: item,
      name: 'color-radio-button-demo',
      inputProps: { 'aria-label': item },
    });

    const controlProps2 = (item: string) => ({
      checked: newNoteContent2 === item,
      onChange: handleChnage2,
      value: item,
      name: 'color-radio-button-demo',
      inputProps: { 'aria-label': item },
    });
   
    const theme = createTheme({
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              scrollbarColor: "#6b6b6b #2b2b2b",
              "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                backgroundColor: "#2b2b2b",
              },
              "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                borderRadius: 8,
                backgroundColor: "#6b6b6b",
                minHeight: 24,
                border: "3px solid #2b2b2b",
              },
              "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                backgroundColor: "#959595",
              },
              "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                backgroundColor: "#959595",
              },
              "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#959595",
              },
              "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                backgroundColor: "#2b2b2b",
              },
            },
          },
        },
      },
    });

    console.log("eeee",newNoteContent)


    useEffect(() => {
      const accessTokenRequest = {
        ...loginRequest,
        account: accounts[0]
      }
      
      if (inProgress === InteractionStatus.None) {
          instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
              // Acquire token silent success
              let accessToken = accessTokenResponse.accessToken;
           
              setNewNoteContent7(accessToken)
             
          
              // Call your API with token
            
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
          }); 
               
             
       
      }
  }, [instance, accounts, inProgress ]);

  
  function handleChange(b: any) {
    b.target.value = b.target.value.trim()
    onInputChange("name", b.target.value, dispatch, formState)
    console.log("oninputchange--->",b.target.value)
    setNewNoteContent(b.target.value)
};

  function handleChange2(g: any) {
    g.target.value = g.target.value.trim()
    onInputChange("account", g.target.value, dispatch, formState)
    setNewNoteContent3(g.target.value)
}; 

function handleChange3(h: any) {
    h.target.value = h.target.value.trim()
    onInputChange("account2", h.target.value, dispatch, formState)
    setNewNoteContent4(h.target.value)
}; 


const handleChangeWhiteSpace = (g: any) => {
  g.target.value = g.target.value.trim();
};

const handleChangeWhiteSpace2 = (h: any) => {
  h.target.value = h.target.value.trim();
};

const handleChangeWhiteSpace3 = (b: any) => {
  b.target.value = b.target.value.trim();
};





return (

 
    <ThemeProvider theme={theme}>
  <ScopedCssBaseline enableColorScheme>

        <>
        </>
  <div className="borderaccordian" >
  <br /> 
        <h1 className="wrapper11">Route 53 Query Engine</h1>
        <br />  
  
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >

            <Typography component="div">
            <Box sx={{ fontWeight: 'bold', fontFamily: 'Lato', fontSize: 'h7.fontSize', m: 0 }}>
            Query database for the AWS Account number.
            </Box>
             
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="container"></div>
              <form onSubmit={(g) => {
                // call the reduxDispatch hook (see above) abnd reference the action to uses..
                reduxDispatch(reset());
                reduxDispatch(fetchNotes2({ content: newNoteContent3, c3: newNoteContent7 }));
                setNewNoteContent3("");
                UpDate("account", " ", dispatch, formState)
                g.preventDefault();
              } }>

           
                <input  
                  
                  id="account"
                  name="account"
                  type="text"
                  placeholder="Enter Hosted Zone" 
                  value = {formState.account.value}
                  onPaste={handleChangeWhiteSpace}
                  className="input"
                  onChange={handleChange2}

                 
                  
                  onBlur={g => {
                  onFocusOut("account", g.target.value, dispatch, formState)
                  }}
                  
                  />
     
    <br />  

              <div className="vertical-center">
                {formState.name.hasError ? 
                  <p>  {formState.account.error} </p> : null}
                
                </div>  

                <div className="vertical-center">
                {!formState.account.hasError ? 
                  <p>  <ScrollDialog name="jon" /></p> : null }
                
                </div>  
                <div className="vertical-center">

                  <Button onClick={handleOpen2}><InfoIcon />Info </Button>
               </div>  
           
           <Modal
             keepMounted
             open={open2}
             onClose={handleClose2}
             aria-labelledby="keep-mounted-modal-title"
             aria-describedby="keep-mounted-modal-description"
           >
             <Box sx={style}>
               <Typography id="keep-mounted-modal-title" variant="h6" component="h3">
               <InfoIcon />
               </Typography>
               <Typography id="keep-mounted-modal-description" sx={{ mt: 3 }}>
               <div className="centeralign">
                <p> Query the database for the AWS account number associated with the Route53 Hosted Zone.  (Please enter the name of fhe Route53 Hosted Zone.) </p>
               </div>
               </Typography>
             </Box>
           </Modal>


            </form>

          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
          <AccordionSummary 
           
            expandIcon={<ExpandMoreIcon />}  
            aria-controls="panel2a-content"  
            id="panel2a-header"
          >
         

            <Typography component="div">
            <Box sx={{ fontWeight: 'bold', fontFamily: 'Lato', fontSize: 'h7.fontSize', m: 0 }}>
            Query database for Route53 Hosted Zones.
            </Box>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="container"></div>
              <form onSubmit={(h) => {
                reduxDispatch(reset());
                reduxDispatch(fetchNotes3({ content: newNoteContent4, c2: newNoteContent7 }));
                setNewNoteContent4("");
                UpDate("account2", " ", dispatch, formState)
                h.preventDefault();
              } }>

              <input  
                  
                  id="account2"
                  name="account2"
                  type="text"
                  placeholder="Enter Account number" 
                  value = {formState.account2.value}
                  onPaste={handleChangeWhiteSpace2}
                  className="input"
                  onChange={handleChange3}

                  //onChange={(e) => setNewNoteContent(e.target.value)}  />
                  //<ScrollDialog parentState={newNoteContent} />
               
                  //onChange={e => {
                  //    onInputChange("name", e.target.value, dispatch, formState)
                   // }  }  
                  
                  onBlur={h => {
                  onFocusOut("account2", h.target.value, dispatch, formState)
                  }}
                  
                  />

                 <div className="vertical-center">
                {formState.account2.hasError ? 
                  <p>  {formState.account2.error} </p> : null}
                
                </div>  

                <div className="vertical-center">
                {!formState.account2.hasError ? 
                  <p>  <ScrollDialog name="jon2" /></p> : null }
                
                </div>  
                <div className="vertical-center">
                       <Button onClick={handleOpen}><InfoIcon />Info </Button>
                    </div>
           
           <Modal
             keepMounted
             open={open}
             onClose={handleClose}
             aria-labelledby="keep-mounted-modal-title2"
             aria-describedby="keep-mounted-modal-description2"
           >
             <Box sx={style}>
               <Typography id="keep-mounted-modal-title2" variant="h6" component="h2">
               <InfoIcon />
               </Typography>
               <Typography id="keep-mounted-modal-description2" sx={{ mt: 2 }}>
               <div className="centeralign">
               <p> Query the database for the Route53 Hosted Zone(s) associated with AWS account Number.  (Please enter AWS account number.) </p> 
               </div>
               </Typography>
             </Box>
           </Modal>
              </form>
          
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography component="div">
            <Box sx={{ fontWeight: 'bold', fontFamily: 'Lato', fontSize: 'h7.fontSize', m: 0 }}>
            Query database for ALL Hosted Zones (Created or Deleted).
            </Box>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="container"></div>
              <form onSubmit={(g) => {
                console.log("hi")
                reduxDispatch(reset());
                reduxDispatch(fetchNotes5({content: newNoteContent6, c2: newNoteContent7 }));
                //setNewNoteContent2("");
                console.log("hi2")
                g.preventDefault();
              } }>

        
                  <div className="wrapper3">Select Record Type...
                <br />
                Created
                  <Radio  {...controlProps('Enabled')} /> 

                
                Deleted
                  <Radio
                {...controlProps('Disabled')} 
                    
              /> 

              </div>

    
                  <p>  <ScrollDialog name="RES" /></p>
      
                  <div className="vertical-center">
                        <Button onClick={handleOpen5}><InfoIcon />Info </Button>

                  </div>
           
           <Modal
             keepMounted
             open={open5}
             onClose={handleClose5}
             aria-labelledby="keep-mounted-modal-title2"
             aria-describedby="keep-mounted-modal-description2"
           >
             <Box sx={style}>
               <Typography id="keep-mounted-modal-title2" variant="h6" component="h2">
               <InfoIcon />
               </Typography>
               <Typography id="keep-mounted-modal-description2" sx={{ mt: 2 }}>

                 <div className="centeralign">
                 <p> Query the database for Route53 Hosted Zones which are either Enabled or Disabled.  (Please select option.) </p> 
                                                                                                    
                
                 </div>
               
               </Typography>
             </Box>
           </Modal>

            </form>

          </Typography>
        </AccordionDetails>
      </Accordion>


        <Accordion> 
          <AccordionSummary 
        
            expandIcon={<ExpandMoreIcon />}  
           
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography component="div">
            <Box sx={{ fontWeight: 'bold', fontFamily: 'Lato', fontSize: 'h7.fontSize', m: 0 }}>
            Query database for Route53 Resource Records.
            </Box>
            </Typography>
          </AccordionSummary> 
          <AccordionDetails>

            <Typography>
              <br />
              <div className="container"></div>
              
              <form onSubmit={(b) => {
          
                reduxDispatch(reset());
                reduxDispatch(fetchNotes({ content: newNoteContent, kol: newNoteContent7 })); 
                setNewNoteContent("");
                UpDate("name", " ", dispatch, formState)
                b.preventDefault();
                
              
              } }>

                <input  
                  
                  id="name2"
                  name="name2"
                  type="text"
                  placeholder="Enter Hosted Zone" 
                  value = {formState.name.value}
                  onPaste={handleChangeWhiteSpace3}
                  className="input"
                  onChange={handleChange}

                  //onChange={(e) => setNewNoteContent(e.target.value)}  />
                  //<ScrollDialog parentState={newNoteContent} />
               
                  //onChange={e => {
                  //    onInputChange("name", e.target.value, dispatch, formState)
                   // }  }  
                  
                
                  onBlur={b => {
                    onFocusOut("name", b.target.value, dispatch, formState)
                      }}

                  />


                
                <div className="vertical-center">
                {formState.name.hasError ? 
                  <p>  {formState.name.error} </p> : null}
                
                </div>  

                <div className="vertical-center">
                  {console.log("ERR",formState.name.hasError)}
                {!formState.name.hasError ? 
                  <p>  <ScrollDialog name="JON3" /></p> : null }
                
                </div>  

                <div className="vertical-center">
                      <Button onClick={handleOpen3}><InfoIcon />Info </Button>
                </div>
           
           <Modal
             keepMounted
             open={open3}
             onClose={handleClose3}
             aria-labelledby="keep-mounted-modal-title2"
             aria-describedby="keep-mounted-modal-description2"
           >
             <Box sx={style}>
               <Typography id="keep-mounted-modal-title2" variant="h6" component="h2">
               <InfoIcon />
               </Typography>
               <Typography id="keep-mounted-modal-description2" sx={{ mt: 2 }}>
               <div className="centeralign">
               <p> Query the database for the Route53 Resource Records associated with Route53 Hosted Zone.  (Please enter the name of the Route53 Hosted Zone.) </p> 
               <p><strong>Note</strong>  Enter the hosted zone name when querying for specific resource records.  The current release does not support queries for specifc records.</p>  
               </div>
               </Typography>
             </Box>
           </Modal>

               

              </form>

            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
       
            <Typography component="div">
            <Box sx={{ fontWeight: 'bold', fontFamily: 'Lato', fontSize: 'h7.fontSize', m: 0 }}>
            Query database for ALL records.
            </Box>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="container"></div>
              <form onSubmit={(g) => {
                console.log("hi")
                reduxDispatch(reset());
                reduxDispatch(fetchNotes4({ content: newNoteContent2, c2: newNoteContent7 }));
                //setNewNoteContent2("");
                setproc(true)
                console.log("proc is",proc)
                console.log("hi2")
                g.preventDefault();
              } }>

        
                  <div className="wrapper3">Select Record Type...
                <br />
                Hosted Zones
                  <Radio  {...controlProps2('HOSTEDZONE')} /> 

                Resource Records
                  <Radio
                {...controlProps2('RESOURCE')} 
                    sx={{
                    color: blue[600],
                    '&.Mui-checked': {
                      color: blue[600],
                },
               }}
              /> 
                Delegated Zones
                  <Radio
                {...controlProps2('DELEGATED')} 
                    
              /> 

              </div>

   
    <br />  
    
                  <p>  <ScrollDialog name={newNoteContent2} /></p>
      
           

            <div className="vertical-center">
               {Spreadsheet(newNoteContent2,proc)}
            </div>
              
               <br />  
               <br />  
               <br />  
               <div className="vertical-center">
                    <Button onClick={handleOpen4}><InfoIcon />Info </Button>
              </div>
           
           <Modal
             keepMounted
             open={open4}
             onClose={handleClose4}
             aria-labelledby="keep-mounted-modal-title2"
             aria-describedby="keep-mounted-modal-description2"
           >
             <Box sx={style}>
               <Typography id="keep-mounted-modal-title2" variant="h6" component="h2">
               <InfoIcon />
               </Typography>
               <Typography id="keep-mounted-modal-description2" sx={{ mt: 2 }}>

                 <div className="centeralign">
                 <p> Query the database for ALL Route53 Resource Records or Route53 Hosted Zones.  (Please select option.) </p> 

                 <p><strong>Note</strong>  Delegated Zones will return ONLY those sub-domains which have been delegated DIRECTLY from the Authoratative DNS Account .</p>                                                                                                      
                
                 </div>
               
               </Typography>
             </Box>
           </Modal>

            </form>

          </Typography>
        </AccordionDetails>
      </Accordion>
        



        <Accordion disabled>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>TBD</Typography>
          </AccordionSummary>
        </Accordion>
  
        <br />
        <br />
        <br />
     
 
        </div>
        </ScopedCssBaseline>
        </ThemeProvider>
    
    );
          
};