import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { useIsAuthenticated } from "@azure/msal-react";
import {  useMsal  } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import Container from "react-bootstrap/Container";
import 'bootstrap/dist/css/bootstrap.css';
import {NavBar, Nav, NavItem} from 'react-bootstrap';



/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
// <h1 className="wrapper10">AWS Route53 Portal</h1>

export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();

    const name = accounts[0] && accounts[0].name;

    return (
        
        <>
            <Navbar bg="black" variant="dark" sticky="top">
                <Container>
                     <Navbar.Brand >AWS Route53 Portal</Navbar.Brand>
                     <a className="navbar-brand" href="/"></a>
                     { isAuthenticated ? <h5 className="namewrapper"> Welcome: {name} </h5> : null }
                     <a className="justify-content-end" href="/"></a>
                     { isAuthenticated ? <SignOutButton /> : <SignInButton /> }
                    
                </Container>
            </Navbar>
            <br />
            <br />
            <br />
       
                {props.children}
           
        
                </>
    );
};

