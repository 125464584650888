import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig-dev";
import 'bootstrap/dist/css/bootstrap.css';
import './features/notes/app.css';
//import { Container, Header, List } from "semantic-ui-react";
//import pkg from 'semantic-ui-react/package.json';




const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

