import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig-dev";
import Button from "react-bootstrap/Button";
import '../features/notes/app.css';

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        
        <Button className="signinout" variant="secondary" onClick={() => handleLogin(instance)}>Sign in</Button>
       
    );
}

